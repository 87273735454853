import classNames from 'classnames'
import React from 'react'

const classNameMap = {
  h1: 'text-6xl',
  h2: 'text-5xl',
  h3: 'text-4xl',
  h4: 'text-3xl',
  h5: 'text-2xl',
  h6: 'text-xl',
  p: '',
  xs: 'text-xs',
  lead: 'text-base',
}

const Text: React.FC<
  React.PropsWithChildren<{
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'xs'
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'lead'
    noMargin?: boolean
    color?: string
    className?: string
    style?: React.CSSProperties
  }>
> = ({ as = 'h1', className, children, color, noMargin, variant, style }) => {
  const textColor = color || (variant === 'lead' ? 'text-bg-5' : 'text-bg-9')

  return React.createElement(
    as,
    {
      style: {
        color: color || '',
        ...style,
      },
      className: classNames(
        textColor,
        classNameMap[variant ?? as],
        { 'font-heading': ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant ?? as) },
        { 'mb-2': !noMargin },
        className
      ),
    },
    children
  )
}

export default Text
