import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useNavData from '../../../hooks/useNavData'
import { getTokenCookie } from '../../../utils/cookie'
import Theme from '../Theme'

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter()

  const [tokenUpdated, setTokenUpdated] = useState(false)
  const navData = useNavData()

  useEffect(() => {
    getTokenCookie()
    setTokenUpdated(true)
  }, [router])

  if (!tokenUpdated) {
    return null
  }

  return (
    <Theme>
      <Head>
        <link rel="icon" href={navData.favicon} key="icon" />
      </Head>
      {children}
    </Theme>
  )
}

export default Layout
