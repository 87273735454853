import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useClickAway } from 'react-use'
import useUser from '../../../hooks/useUser'
import Avatar from '../../Atoms/Avatar'

type Props = {
  showUserMenu: boolean
  setShowUserMenu: (show: boolean) => void
}
const UserMenu: React.FC<Props> = ({ showUserMenu, setShowUserMenu }) => {
  const { t } = useTranslation(['navbar'])
  const me = useUser()
  const router = useRouter()
  const ref = useRef(null)
  const lgScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const handleUserMenuToggle = (): void => {
    setShowUserMenu(!showUserMenu)
  }

  useClickAway(ref, () => setShowUserMenu(false))

  return (
    <div
      className="group h-11 relative"
      tabIndex={0}
      role="button"
      onClick={handleUserMenuToggle}
      onKeyPress={handleUserMenuToggle}
      ref={ref}
    >
      <Avatar src={me.photoSizes['medium']} alt="user" height={44} width={44} />
      <div
        className={classNames(
          { block: showUserMenu },
          { hidden: !showUserMenu },
          'min-w-[8rem]',
          'w-auto',
          'h-auto',
          'absolute',
          'bg-theme-background',
          'rounded',
          { 'right-0': !lgScreen },
          { 'left-0': lgScreen },
          'border',
          'border-bg-4',
          'shadow-lg',
          'drop-shadow-none',
          'z-[500]'
        )}
      >
        <div className="block text-sm font-bold py-2 px-2 transition-colors rounded-t border-b-2">
          {me.username}
        </div>
        <Link href="/user">
          <a className="block hover:bg-bg-4 text-sm font-semibold py-1 px-2 transition-colors rounded-t">
            {t('user.assets')}
          </a>
        </Link>
        <Link href="/user/favorites">
          <a className="block hover:bg-bg-4 text-sm font-semibold py-1 px-2 transition-colors">
            {t('user.favorited')}
          </a>
        </Link>
        <Link href="/user/activity">
          <a className="block hover:bg-bg-4 text-sm font-semibold py-1 px-2 transition-colors">
            {t('user.activity')}
          </a>
        </Link>
        <Link href="/user/settings">
          <a className="block hover:bg-bg-4 text-sm font-semibold py-1 px-2 transition-colors">
            {t('user.settings')}
          </a>
        </Link>
        <button
          className="flex w-full hover:bg-bg-4 text-sm font-semibold py-1 px-2 transition-colors rounded-b"
          onClick={() => {
            localStorage.removeItem('seenAirdrops')
            localStorage.removeItem('seenVerify')

            router.push('/logout')
          }}
        >
          {t('user.logout')}
        </button>
      </div>
    </div>
  )
}

export default UserMenu
