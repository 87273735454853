/* eslint-disable @next/next/no-img-element */
import { APINFT } from '@betablocks/shared/lib/api/nfts'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import XIcon from '../../../assets/images/x-icon.svg'
import Button from '../../Atoms/NewButton'

type Props = {
  onClose: () => void
  gifts: APINFT[]
  isDonation?: boolean
  alreadyClaimed?: boolean
}

const GiftsPopup: React.FC<Props> = ({
  onClose,
  gifts,
  isDonation = false,
  alreadyClaimed = false,
}) => {
  const { t } = useTranslation('common')

  function handleSeeGift(gift: APINFT): void {
    window.open(`${window.location.origin}/product/${gift.contractAddress}/${gift.id}/`, '_blank')
  }
  return (
    <div
      className={classNames(
        'justify-center items-center flex fixed inset-0 z-[1000] outline-none focus:outline-none transition-all bg-black bg-opacity-70 '
      )}
    >
      <div
        className={classNames(
          'w-[700px]',
          'w-full',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'rounded-lg',
          'shadow-lg',
          'bg-white',
          'transition-all',
          'sm:p-10',
          'p-5',
          'sm:m-20',
          'm-3'
        )}
      >
        <button
          onClick={onClose}
          className="self-end transform hover:scale-110 active:scale-90 cursor-pointer"
        >
          <Image src={XIcon} alt="close" />
        </button>
        <article className="flex flex-col items-center text-center mb-5">
          <h3 className="font-bold  text-3xl">
            {isDonation
              ? t('donation.donatedTitle')
              : alreadyClaimed
              ? t('airdrops.claimedTitle')
              : alreadyClaimed
              ? t('airdrops.alreadyClaimedTitle')
              : t('airdrops.receivedTitle')}
          </h3>
          {alreadyClaimed && isDonation && <p>{t('donation.donatedSubtitle')}</p>}
          {!alreadyClaimed && (
            <p className="mt-0 mb-0">
              {alreadyClaimed
                ? t('airdrops.alreadyClaimedSubtitle')
                : t('airdrops.receivedSubtitle')}
            </p>
          )}
        </article>

        <div className="flex items-center flex-wrap justify-center overflow-y-auto h-[350px] md:w-[650px] gap-10  ">
          {gifts?.map((gift) => (
            <div
              key={gift.id}
              className="flex flex-col md:flex-row items-center p-3 gap-5  md:w-[622px] shadow-md"
            >
              <img src={gift?.file} alt={gift.name} className="w-[180px] h-[180px] object-cover" />
              <div className="flex flex-col md:items-start items-center text-center md:text-start  md:w-[382px] leading-tight  gap-5">
                <p className="capitalize font-bold  text-lg md:text-[24px] ">{gift.name}</p>
                <Button
                  variant="primary"
                  onClick={() => handleSeeGift(gift)}
                  className="max-w-[180px]"
                >
                  {t('airdrops.button.claim')}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GiftsPopup
