import { handleError } from '@betablocks/shared/lib/api/base'
import { APIEdition } from '@betablocks/shared/lib/api/editions'
import { APINFT } from '@betablocks/shared/lib/api/nfts'
import classNames from 'classnames'
import Head from 'next/head'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsync, useClickAway } from 'react-use'
import XIcon from '../../../assets/icons/DifferentXIcon'
import LoginPaneContext from '../../../contexts/LoginPaneContext'
import useApi from '../../../hooks/useApi'
import useUser from '../../../hooks/useUser'
import StoreFooter from '../../Molecules/StoreFooter'
import Layout from '../../Organisms/Layout'
import SignInForm from '../../forms/SignInForm'
import GiftsPopup from '../GiftPopup'
import Navbar from '../Navbar'
import StorefrontSidebar from '../StorefrontSidebar'
import VerifyEmailPopup from '../VerifyEmailPopp'

type Props = {
  progressBar?: number
  restrict?: boolean
  showLoginMenu?: boolean
  className?: string
  noVerticalPadding?: boolean
  noHorizontalPadding?: boolean
  checkout?: boolean
  checkoutForm?: ReactNode
  isProductPage?: boolean
  showAirdropPopup?: boolean
  edition?: APIEdition
  hideNav?: boolean
  hideFooter?: boolean
  noRedirectOnLogo?: boolean
}

const PagesLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  progressBar,
  restrict,
  showLoginMenu = false,
  className,
  noVerticalPadding = false,
  noHorizontalPadding = false,
  checkout,
  checkoutForm,
  isProductPage,
  hideNav = false,
  noRedirectOnLogo = false,
}) => {
  const { t } = useTranslation(['navbar', 'common', 'product-page'])
  const me = useUser()
  const api = useApi()
  const [gifts, setGifts] = useState<APINFT[]>(null)
  const [showVerifyEmail, setShowVerifyEmail] = useState(false)

  const checkPendingAirdrops = useCallback(async () => {
    // if hasn't seen the verify email popup, don't show airdrop popup
    if (isProductPage || !me?.verified) {
      return
    }
    try {
      const seen = localStorage.getItem('seenAirdrops')

      const searchParams = {
        ordering: 'newest' as const,
      }
      const res = await api.nfts.list(1, undefined, searchParams)
      if (!res?.results?.length) {
        return
      }

      const canPurchase = res.results.filter(
        (nft) =>
          nft?.userCanPurchase &&
          nft?.saleType === 'airdrop' &&
          !nft?.hasEdition &&
          !nft?.isDraft &&
          nft?.listed
      )
      if (!canPurchase?.length) {
        return
      }
      const seenArr = new Set(seen?.split(','))
      const nfts = canPurchase.filter((n) => !seenArr.has(n.id.toString()))
      //   localStorage.setItem('seenAirdrops', canPurchase.map((n) => n.id).join(','))

      if (!nfts || !nfts.length) return
      setGifts(nfts)
      setShowGiftPopup(true)
    } catch (e) {
      await handleError(e)
    }
  }, [api.nfts, isProductPage, me?.verified])

  useAsync(async () => {
    checkPendingAirdrops()
  }, [me, showVerifyEmail])

  const [hamburgerShow, setHamburgerShow] = useState(false)
  const [showLogin, setShowLogin] = useState(!me && showLoginMenu)
  const pulldownRef = useRef(null)
  const [showGiftPopup, setShowGiftPopup] = useState(false)

  useClickAway(pulldownRef, () => {
    setShowLogin(false)
  })

  const handleEsc = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && showLogin) {
        setShowLogin(false)
      }
    },
    [showLogin]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  const handleHamburgerToggle = (): void => {
    setHamburgerShow((prev) => !prev)
  }

  const handleShowLogin = (): void => {
    if (hamburgerShow) {
      setHamburgerShow((prev) => !prev)
    }

    setShowLogin((prev) => !prev)
  }

  const handleViewVerifyPopup = useCallback(async (): Promise<void> => {
    const seenVerify = localStorage.getItem('seenVerify')
    if (!me?.verified && (!seenVerify || seenVerify === 'false')) {
      setShowVerifyEmail(true)
    }
  }, [me?.verified])

  useEffect(() => {
    if (me && !isProductPage) handleViewVerifyPopup()
  }, [handleViewVerifyPopup, me, isProductPage])

  const handleCloseVerifyEmailPopup = async (): Promise<void> => {
    localStorage.setItem('seenVerify', 'true')
    setShowVerifyEmail(false)
    await checkPendingAirdrops()
  }

  return (
    <Layout>
      <Head>
        <title>{t('title', { ns: 'common' })}</title>
      </Head>

      <Navbar
        mobileHamburgerShow={hamburgerShow}
        onMobileHamburgerToggle={handleHamburgerToggle}
        loginClick={handleShowLogin}
        hideNav={hideNav}
        noRedirectOnLogo={noRedirectOnLogo}
      />

      <LoginPaneContext.Provider value={{ toggleLoginPane: handleShowLogin }}>
        <div className={classNames('flex-1', 'flex', 'flex-col', 'relative', 'content-area')}>
          <div
            className={classNames(
              'absolute',
              'w-full',
              'h-full',
              'bg-theme-background',
              'z-50',
              'lg:hidden',
              'shadow-inner',
              'shadow-theme-primaryLight',
              hamburgerShow ? '' : 'right-full'
            )}
          >
            <StorefrontSidebar loginClick={handleShowLogin} />
          </div>

          <div
            className={classNames(
              'lg:flex-1',
              'lg:min-h-full',
              'overflow-y-auto',
              'bg-theme-background'
            )}
          >
            <div
              className={classNames(
                { 'bg-black bg-opacity-70': showLogin || checkout },
                { 'pointer-events-none': !showLogin || !checkout },
                'transition-all',
                'absolute',
                'top-0',
                'left-0',
                'right-0',
                'bottom-0',
                'z-30'
              )}
              role="none"
              onClick={() => setShowLogin(false)}
            />

            <div
              ref={pulldownRef}
              className={classNames(
                'absolute',
                'transition-all',
                { 'bg-bg-0': showLogin },
                { 'top-0': showLogin || checkout },
                { '-top-full': !showLogin && !checkout },
                'left-0',
                'right-0',
                'z-40',
                'overflow-y-scroll',
                { 'h-full': checkout }
              )}
            >
              {showLogin ? (
                <>
                  <span
                    onClick={handleShowLogin}
                    onKeyPress={handleShowLogin}
                    tabIndex={0}
                    role="button"
                    className="flex flex-row-reverse pr-4 pt-4"
                  >
                    <XIcon className="fill-current" />
                  </span>
                  <div className="p-10">
                    <SignInForm setShowLogin={setShowLogin} />
                  </div>
                </>
              ) : checkoutForm ? (
                checkoutForm
              ) : null}
            </div>

            <main
              id="content-main"
              className={classNames(
                { 'max-w-7xl grow container px-12 py-10 mx-auto': restrict },
                'relative',
                'bg-theme-background',
                'min-h-[80vh]',
                { 'px-4': !noHorizontalPadding, 'py-10': !noVerticalPadding },
                className
              )}
            >
              {progressBar && (
                <div
                  style={{ width: `${progressBar}%` }}
                  className={classNames(
                    'absolute',
                    'top-0',
                    'left-0',
                    'right-0',
                    'shadow-none',
                    'flex',
                    'flex-col',
                    'text-center',
                    'whitespace-nowrap',
                    'text-bg-0',
                    'justify-center',
                    'bg-theme-secondary',
                    'z-10',
                    'h-1',
                    'shrink-0'
                  )}
                />
              )}
              {children}

              {showGiftPopup && !showVerifyEmail && (
                <GiftsPopup onClose={() => setShowGiftPopup(false)} gifts={gifts} />
              )}
              {showVerifyEmail && <VerifyEmailPopup onClose={handleCloseVerifyEmailPopup} />}
            </main>

            <StoreFooter noRedirectOnLogo={noRedirectOnLogo} />
          </div>
        </div>
      </LoginPaneContext.Provider>
    </Layout>
  )
}

export default PagesLayout
