import { handleError } from '@betablocks/shared/lib/api/base'
import { APIWallet } from '@betablocks/shared/lib/api/wallets'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useQRCode } from 'next-qrcode'
import React, { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import ArrowIcon from '../../../assets/icons/ArrowIcon'
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon'
import ExportIcon from '../../../assets/icons/ExportIcon'
import PreviewIcon from '../../../assets/icons/PreviewIcon'
import useApi from '../../../hooks/useApi'
import useBetablocksWallets from '../../../hooks/useBetaBlocksWallets'
import { Toast } from '../../../utils/toast'
import Button from '../../Atoms/NewButton'
import Loading from '../Loading'

const WalletPicker: React.FC = () => {
  const api = useApi()
  const { t } = useTranslation(['navbar'])
  const dropdownRef = useRef(null)

  const { SVG } = useQRCode()

  const { betablocksWallets, setActiveWallet: setWallet, activeWallet } = useBetablocksWallets()

  const [exportingWallet, setExportingWallet] = useState<APIWallet>(null)
  const [privateKey, setPrivateKey] = useState<string>(null)
  const [privateKeyLoading, setPrivateKeyLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!activeWallet && betablocksWallets) {
      const custodial = betablocksWallets.find((wallet) => wallet.isTatumWallet)
      setWallet(custodial)
    }
  }, [activeWallet, betablocksWallets, setWallet])

  const handleSetExportingWallet = (
    e: React.MouseEvent<HTMLOrSVGElement>,
    wallet: APIWallet
  ): void => {
    e.stopPropagation()
    setExportingWallet(wallet)
  }
  const handleResetExportingWallet = (e: React.MouseEvent<HTMLOrSVGElement>): void => {
    e.stopPropagation()
    setExportingWallet(null)
    setPrivateKey(null)
  }

  const handleRevealPrivateKey = async (e: React.MouseEvent<HTMLOrSVGElement>): Promise<void> => {
    e.stopPropagation()

    setPrivateKeyLoading(true)
    if (!exportingWallet || privateKey) {
      setPrivateKey(null)
      setPrivateKeyLoading(false)
      return
    }

    let apiPrivateKey: string = null
    try {
      const { key } = await api.wallets.exportPrivateKey(exportingWallet.id)
      apiPrivateKey = key
    } catch (e) {
      const err = await handleError(e)
      console.error(err.message)
    }
    setPrivateKeyLoading(false)
    setPrivateKey(apiPrivateKey)
  }

  const handleSetActiveWallet = (
    e: React.MouseEvent<HTMLOrSVGElement>,
    wallet: APIWallet
  ): void => {
    e.stopPropagation()
    e.preventDefault()
    setWallet(wallet)
  }

  const handleCopyPrivateKey = (e: React.MouseEvent<HTMLOrSVGElement>): void => {
    e.stopPropagation()
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(privateKey)
    }
    Toast.success(
      t('wallets.copiedPrivateKey', { address: exportingWallet?.name || exportingWallet.address }),
      'copied-active-pk'
    )
  }

  const handleCopyAddress = (e: React.MouseEvent<HTMLOrSVGElement>): void => {
    e.stopPropagation()
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(exportingWallet.address)
    }
    Toast.success(
      t('wallets.copiedAddress', { address: exportingWallet.address }),
      'copied-address',
      'break-all'
    )
  }

  useClickAway(dropdownRef, () => {
    setPrivateKeyLoading(null)
    setExportingWallet(null)
    setPrivateKey(null)
  })

  return (
    <div className="w-full relative inline-block rounded-md" ref={dropdownRef}>
      <Menu>
        {activeWallet ? (
          <Menu.Button as={'div'} className="w-full">
            <Button
              variant="primary"
              className="font-normal w-full lg:w-40 px-4 overflow-hidden "
              icon={<ChevronDownIcon className="fill-current" />}
              iconAlign="right"
            >
              {activeWallet?.address.slice(0, 6)}...{activeWallet?.address.slice(-4)}
            </Button>
          </Menu.Button>
        ) : (
          <Button variant="primary" href="/user/settings/wallets/" className="w-full lg:w-40">
            {t('wallets.createBetaBlocksWallet')}
          </Button>
        )}
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {betablocksWallets && (
            <Menu.Items
              static
              className="bg-theme-background rounded-md pt-2 overflow-hidden border border-bg-5 absolute right-0 origin-top-right z-[10000]"
            >
              {exportingWallet ? (
                <div className="w-48 md:w-[300px] p-3 flex flex-col text-sm">
                  <span>
                    <button
                      type="button"
                      className="flex flex-row gap-2 text-theme-primary mb-1 cursor-pointer hover:animate-pulse active:scale-90"
                      onClick={handleResetExportingWallet}
                    >
                      <ArrowIcon width={14} height={14} className="mt-1 text-theme-primary" />
                      <span className="capitalize font-bold">{t('wallets.back')}</span>
                    </button>
                  </span>
                  <hr className="opacity-25" />
                  <div className="flex flex-col text-xs">
                    <button
                      onClick={handleCopyAddress}
                      className="flex flex-col items-start rounded-sm hover:bg-theme-primaryLight hover:animate-pulse p-3 -mx-3 mb-2 cursor-copy"
                    >
                      <span className="text-theme-dark font-bold">{exportingWallet.name}</span>
                      <span>{`${exportingWallet.address.slice(
                        0,
                        6
                      )}...${exportingWallet.address.slice(-6)}`}</span>
                    </button>
                    <button onClick={handleRevealPrivateKey} className="mt-2">
                      <div className="text-theme-primary flex flex-col capitalize ">
                        <span className="flex flex-row hover:animate-pulse -mb-1 gap-2 active:scale-95 font-semibold">
                          {t('wallets.revealPrivateKey')} <PreviewIcon />
                        </span>
                        {privateKey ? (
                          <button type="button" onClick={handleCopyPrivateKey}>
                            <div className="w-full border-2 rounded-md break-all cursor-copy text-xxs text-left mt-2 p-2 text-black">
                              {privateKey}

                              <div className="my-2 flex flex-col items-center">
                                <SVG
                                  text={privateKey}
                                  options={{
                                    margin: 2,
                                    width: 250,
                                    color: {
                                      dark: '#000000',
                                      light: '#ffffff',
                                    },
                                  }}
                                />
                                <p className="text-center italic">{t('wallets.scanQrCode')}</p>
                              </div>
                            </div>
                          </button>
                        ) : privateKeyLoading ? (
                          <div className="bg-bg-4 h-12 w-full hover:bg-bg-6 mt-2">
                            <Loading />
                          </div>
                        ) : (
                          <div className="bg-theme-primary h-12 w-full hover:opacity-80 mt-2" />
                        )}
                      </div>
                    </button>

                    <span className="uppercase italic opacity-75 mt-1 text-center cursor-default">
                      {t('wallets.neverShare')}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:w-[300px] items-center">
                  <div className="text-center overflow-hidden truncate font-bold my-2">
                    BetaBlocks Wallet
                  </div>

                  {betablocksWallets.map((wallet) => (
                    <Menu.Item
                      onClick={(e) => handleSetActiveWallet(e, wallet)}
                      key={wallet.id}
                      as="div"
                      className={classNames(
                        'w-40 md:min-w-[250px] bg-white',
                        'cursor-default',
                        'flex flex-col justify-between ',
                        'font-display text-sm text-bg-11',
                        'px-4 py-2 whitespace-nowrap',
                        'cursor-pointer  active:bg-theme-primaryLighter ',
                        activeWallet?.address === wallet.address && 'bg-theme-primary'
                      )}
                    >
                      <div className="flex flex-col items-center w-full">
                        <div className="overflow-hidden truncate font-bold">{wallet.name}</div>
                      </div>
                      <div className="flex flex-col items-center ">
                        <div className="flex-1">
                          {wallet.address.slice(0, 5)}...{wallet.address.slice(-4)}
                        </div>
                        <button
                          className="flex w-48 justify-center align-middle text-white bg-theme-primary p-2 mt-2 my-1 rounded-md"
                          onClick={(e) => handleSetExportingWallet(e, wallet)}
                        >
                          <p>{t('wallets.exportWallet')} </p>
                          <ExportIcon
                            className="ml-1 mt-[0.5px] h-[16px] cursor-pointer hover:scale-105 active:scale-95 "
                            fill="white"
                          />
                        </button>
                      </div>
                    </Menu.Item>
                  ))}
                </div>
              )}
              {!exportingWallet && (
                <p className="text-center text-xxs max-w-[150px] mx-auto my-2 text-custom-gray3">
                  {t('wallets.exportToAnotherApp')}
                </p>
              )}
            </Menu.Items>
          )}
        </Transition>
      </Menu>
    </div>
  )
}

export default WalletPicker
