import classNames from 'classnames'
import React from 'react'
import VerificationInput, { VerificationInputProps } from 'react-verification-input'

type Props = VerificationInputProps & {
  hasError?: boolean
}

const CodeEntry = React.forwardRef<HTMLInputElement, Props>(({ hasError, ...props }, ref) => {
  return (
    <VerificationInput
      ref={ref}
      removeDefaultStyles
      autoFocus // eslint-disable-line jsx-a11y/no-autofocus
      classNames={{
        container: 'flex h-20 mb-6 gap-4',
        character: classNames(
          'border',
          'rounded',
          'text-3xl',
          'font-medium',
          'flex',
          'items-center',
          'justify-center',
          'h-full',
          'w-full',
          'transition-all'
        ),
        characterInactive: classNames({
          'border-bg-1': !hasError,
        }),
        characterSelected: classNames('!border-theme-primary'),
      }}
      {...props}
    />
  )
})

CodeEntry.displayName = 'CodeEntry'

export default CodeEntry
