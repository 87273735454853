import { APIWallet } from '@betablocks/shared/lib/api/wallets'
import { useContext } from 'react'
import { BetaBlocksWalletContext } from '../components/Widgets/BetaBlocksWalletProvider'

type Return = {
  betablocksWallets: APIWallet[]
  activeWallet: APIWallet
  setActiveWallet: (wallet: APIWallet) => void
}
export default function useBetaBlocksWallets(): Return {
  const { betablocksWallets, activeWallet, setActiveWallet } = useContext(BetaBlocksWalletContext)
  return { betablocksWallets, activeWallet, setActiveWallet }
}
