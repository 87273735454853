import { APILocaleChoices } from '@betablocks/shared/lib/api/users'
import { Menu, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon'
import DarkLangIcon from '../../../assets/icons/DarkLangIcon'
import LangIcon from '../../../assets/icons/LangIcon'
import useApi from '../../../hooks/useApi'
import useNavData from '../../../hooks/useNavData'
import useStorefront from '../../../hooks/useStorefront'
import useUser from '../../../hooks/useUser'

const LanguagePicker: React.FC = () => {
  const router = useRouter()
  const { theme } = useNavData()
  const api = useApi()
  const user = useUser()

  const storefront = useStorefront()
  const isBV = storefront?.domainName.includes('bv')

  const handleLocaleChange = async (l: string): Promise<void> => {
    if (user) {
      try {
        await api.users.updateMe({ locale: l as APILocaleChoices })
      } catch (e) {
        return
      }
    }
    router.push(router.asPath, router.asPath, { locale: l })
  }

  useEffect(() => {
    if (isBV && router.locale !== 'pt') {
      handleLocaleChange('pt')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <span className="relative inline-block">
      {!isBV && (
        <Menu>
          <Menu.Button className="btn-plain text-sm font-normal px-4 py-2 uppercase flex flex-row items-center">
            {theme.themeData.dark ? (
              <DarkLangIcon className="mr-2" />
            ) : (
              <LangIcon className="mr-2" />
            )}
            {router.locale || router.defaultLocale}
            <ChevronDownIcon className="ml-2 fill-current" />
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="bg-theme-background rounded-md border border-bg-5 absolute right-0 origin-top-right z-[10000]">
              {router.locales.map((l) => (
                <Menu.Item key={l} as="div" onClick={async () => await handleLocaleChange(l)}>
                  <a className="block font-display text-sm text-bg-11 uppercase px-4 py-2 whitespace-nowrap cursor-pointer hover:bg-theme-primary">
                    {`${new Intl.DisplayNames(l, { type: 'language' }).of(l)} (${l})`}
                  </a>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </span>
  )
}

export default LanguagePicker
