import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'

type Props = React.PropsWithChildren<{
  icon?: ReactNode
  href?: string
  onClick?: () => void
  indent?: boolean
}>

const SettingsSidebarItem = React.forwardRef<HTMLAnchorElement, Props>(function SettingsSidebarItem(
  { children, icon, href, onClick, indent }: Props,
  ref
) {
  const router = useRouter()
  const isActive = router.asPath === href

  return (
    <a
      href={href}
      ref={ref}
      onClick={onClick}
      className={classNames('block px-4 py-2 transition-colors hover:bg-bg-5', {
        'font-bold': isActive,
        'text-theme-primary': isActive,
        'font-medium': !isActive,
        'text-bg-6': !isActive,
      })}
    >
      <li>
        {icon ? <span className="ml-4 w-2.5">{icon}</span> : null}
        <span className={classNames('ml-4 inline-block', { 'ml-10': indent })}>{children}</span>
      </li>
    </a>
  )
})

export default SettingsSidebarItem
