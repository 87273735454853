import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon'
import useNavData from '../../../hooks/useNavData'

const MoreNavBar: React.FC = () => {
  const { t } = useTranslation('navbar')
  const router = useRouter()
  const navData = useNavData()

  const isActive = router.asPath.includes('/more/')

  return (
    <span className="relative inline-block z-[1100]">
      <Menu>
        <Menu.Button
          className={classNames(
            'transition-all hover:text-theme-primary btn-plain text-xs flex flex-row items-center',
            {
              'font-black text-theme-primary': isActive,
              'font-normal text-bg-11 opacity-75 hover:opacity-100 transition-opacity': !isActive,
            }
          )}
        >
          <>
            {t('navbar.navbarMore')}
            <ChevronDownIcon className="ml-2 fill-current" />
          </>
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              'bg-theme-background border border-bg-10 absolute right-0 z-[1100] origin-top-right grid',
              navData.staticPages.length > 1 ? 'grid-cols-2 w-600px' : 'w-300px'
            )}
          >
            {navData.staticPages.map((item) => (
              <Menu.Item key={item.slug}>
                <div className="flex flex-row h-20 px-3 py-3 hover:bg-bg-2 transition-colors">
                  <Link href={`/more/${item.slug}/`}>
                    <a className="flex flex-1 flex-row cursor-pointer">
                      {item.pageIcon ? (
                        <div className="w-14 h-14 relative">
                          <Image
                            src={item.pageIcon}
                            unoptimized
                            layout="fill"
                            objectFit="cover"
                            alt="icon"
                            className="rounded"
                          />
                        </div>
                      ) : (
                        <div className="w-14 h-14 relative" />
                      )}

                      <div className="w-52 pl-2">
                        <div className="text-sm uppercase line-clamp-1 text-bg-10 font-bold mb-1">
                          {item.title}
                        </div>
                        <div className="text-xs text-bg-7 line-clamp-2 font-normal">
                          {item.summary}
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  )
}

export default MoreNavBar
