import CircularProgress from '@mui/material/CircularProgress'

const Loading: React.FC<{ text?: string }> = ({ text }) => {
  // make a full screen loading overlay with a spinner using tailwind classes
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center gap-8 bg-[rgba(0,0,0,0.75)] z-[100] select-none">
      <CircularProgress />
      {text && <div className="text-center text-white">{text}</div>}
    </div>
  )
}

export default Loading
