import { APISocialApp } from '@betablocks/shared/lib/api/auth'
import { handleError } from '@betablocks/shared/lib/api/base'
import classNames from 'classnames'
import * as EmailValidator from 'email-validator'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Apple from '../../../assets/icons/social/Apple'
import Fanvestor from '../../../assets/icons/social/Fanvestor'
import Google from '../../../assets/icons/social/Google'
import Office from '../../../assets/icons/social/Office'
import useApi from '../../../hooks/useApi'
import useNavData from '../../../hooks/useNavData'
import useStorefront from '../../../hooks/useStorefront'
import { setTokenCookie } from '../../../utils/cookie'
import CodeEntry from '../../Atoms/CodeEntry'
import DividerWithText from '../../Atoms/DividerWithText'
import LabelledInput from '../../Atoms/LabelledInput'
import Button from '../../Atoms/NewButton'
import Text from '../../Atoms/Text'

type Inputs = {
  email: string
  password: string
  code: string
}

type ErrorType = {
  detail?: string
}

const SignInForm: React.FC<{ setShowLogin: (b: boolean) => void }> = ({ setShowLogin }) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const api = useApi()
  const path = router.pathname
  const navData = useNavData()

  const { isEmailSigninEnabled } = useStorefront()
  const { oauthApplications } = navData

  const { token: ephemeralToken } = router.query

  const [isLoading, setIsLoading] = useState(false)
  const [isBackupCode, setIsBackupCode] = useState(false)
  const codeRef = useRef(null)

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Inputs>()
  const [error, setError] = useState<ErrorType>({})

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (data) => {
      let token: string

      setError({})
      setIsLoading(true)
      reset({ code: '' })

      try {
        if (ephemeralToken) {
          ;({ token } = await api.auth.loginWithCode({
            ephemeralToken: ephemeralToken as string,
            code: data.code,
          }))
        } else {
          ;({ token } = await api.auth.login(data))
        }
        reset()
      } catch (e) {
        const err = await handleError(e)
        const { ephemeralToken: tk } = err.detail

        if (tk) {
          router.push({ pathname: path, query: { token: tk as unknown as string } }, undefined, {
            shallow: true,
          })
          codeRef.current?.focus()
          return
        }

        setError(err.detail)
        return
      } finally {
        setIsLoading(false)
      }

      setTokenCookie(token)

      const { next } = router.query
      if (next) {
        router.push(next as string)
      } else {
        router.reload()
      }
    },
    [api.auth, ephemeralToken, path, reset, router]
  )

  const handleBackupCodeToggle = (): void => {
    setIsBackupCode((prev) => !prev)
    requestAnimationFrame(() => codeRef.current?.focus())
  }

  const handleCodeBackClick = (): void => {
    router.push({ pathname: path, query: { token: undefined } }, undefined, { shallow: true })
    setValue('code', '')
    reset()
  }

  const renderLogo = (provider: APISocialApp['provider']): React.ReactNode | undefined => {
    switch (provider) {
      case 'apple':
        return <Apple />
      case 'google':
        return <Google />
      case 'microsoft':
        return <Office />
      case 'fanvestor':
        return <Fanvestor />
      default:
        return undefined
    }
  }

  const renderLoginForm = (): React.ReactNode => {
    if (!isEmailSigninEnabled) {
      return null
    }

    if (ephemeralToken) {
      return null
    }

    return (
      <>
        {!!error &&
          Array.from(Object.values(error)).map((val, index) => (
            <div className="text-red-1 text-xs font-bold pb-1" key={index}>
              {val}
            </div>
          ))}

        <LabelledInput
          title={t('login.form.email')}
          placeholder={t('login.form.emailPlaceholder')}
          errorText={errors.email ? errors.email.message : undefined}
          disabled={isLoading}
          {...register('email', {
            required: `${t('login.form.required')}`,
            validate: (value) =>
              !EmailValidator.validate(value) ? `${t('login.form.invalidEmail')}` : undefined,
          })}
        />

        <LabelledInput
          title={t('login.form.password')}
          errorText={errors.password ? errors.password.message : undefined}
          disabled={isLoading}
          type="password"
          placeholder="••••••••••••••••"
          {...register('password', {
            required: `${t('login.form.required')}`,
          })}
        >
          <div className="flex flex-row-reverse">
            <Button href="/recover-password" variant="textBlue" align="right">
              {t('login.form.forgotPassword')}
            </Button>
          </div>
        </LabelledInput>

        <div className="mb-6 flex flex-row gap-4 justify-center">
          <Button
            type="submit"
            variant="primary"
            className="w-48"
            onSubmit={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {t('login.form.button.signin')}
          </Button>
        </div>

        <Text className="flex-1 text-center" as="p" color="text-indigo-1">
          {t('login.form.dontHaveAccount')}{' '}
          {path.startsWith('/signup') ? (
            <a
              onClick={() => setShowLogin(false)}
              onKeyDown={() => setShowLogin(false)}
              className="text-theme-primary hover:underline cursor-pointer"
              role="button"
              tabIndex={0}
            >
              {t('login.form.createAccount')}
            </a>
          ) : (
            <Link href="/signup" passHref>
              <a className="text-theme-primary hover:underline cursor-pointer">
                {t('login.form.createAccount')}
              </a>
            </Link>
          )}
        </Text>

        {oauthApplications.length > 0 ? (
          <DividerWithText>{t('login.form.divider.or')}</DividerWithText>
        ) : null}
      </>
    )
  }

  const renderMFAForm = (): React.ReactNode => {
    if (!ephemeralToken) {
      return null
    }

    return (
      <>
        {!isBackupCode ? (
          <Text as="p" color="text-bg-7" noMargin className="mb-6 text-center">
            {t('login.mfa.subtitle')}
          </Text>
        ) : null}

        {!!error &&
          Array.from(Object.values(error)).map((val, index) => (
            <div className="text-red-1 text-xs font-bold pb-1" key={index}>
              {val}
            </div>
          ))}

        <Controller
          control={control}
          name="code"
          rules={{ required: `${t('login.form.required')}` }}
          render={({ field: { onChange, onBlur, value } }) =>
            isBackupCode ? (
              <LabelledInput
                ref={codeRef}
                title={t('login.form.backupCode')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorText={errors.code?.message}
              />
            ) : (
              <CodeEntry
                ref={codeRef}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                hasError={!!error.detail}
                validChars="1234567890"
              />
            )
          }
        />

        <div className="mb-6 flex flex-row gap-4 justify-center">
          <Button
            type="submit"
            variant="primary"
            className="w-48"
            onSubmit={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {t('login.form.button.signin')}
          </Button>
          <Button variant="textBlack" onClick={handleCodeBackClick}>
            {t('login.form.cancel')}
          </Button>
        </div>

        <div className="mb-6 flex flex-row gap-4 justify-center">
          <Button
            type="submit"
            variant="textBlue"
            className="w-48"
            disabled={isLoading}
            onClick={handleBackupCodeToggle}
          >
            {t('login.form.button.backupCode')}
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="container flex flex-col my-20 items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-1/2 lg:w-1/3 relative">
        <Text
          as="h3"
          color="text-bg-10"
          noMargin
          className={classNames(
            {
              'mb-6': !ephemeralToken,
              'mb-4': ephemeralToken && !isBackupCode,
              'mb-14': ephemeralToken && isBackupCode,
            },
            'font-black',
            'text-center'
          )}
        >
          {ephemeralToken ? t('login.mfa.title') : t('login.welcome')}
        </Text>

        {renderLoginForm()}
        {renderMFAForm()}

        {!ephemeralToken && (
          <div className="flex flex-row flex-wrap gap-2.5 justify-center">
            {oauthApplications.map((provider) => (
              <Button
                key={provider.provider}
                variant="default"
                outline
                icon={renderLogo(provider.provider)}
                iconAlign="left"
                href={`/api/v1/auth/social/${provider.provider}/login/`}
                onClick={() => setIsLoading(true)}
                disabled={isLoading}
                locale={false}
                className="capitalize"
              >
                {provider.name || provider.provider}
              </Button>
            ))}
          </div>
        )}
      </form>
    </div>
  )
}
export default SignInForm
