import { ReactText } from 'react'
import { Slide, toast, ToastContent } from 'react-toastify'

export class Toast {
  static success(
    content: ToastContent,
    toastId?: string,
    bodyClass = 'break-words',
    delay?: number
  ): ReactText {
    return toast.success(content, {
      toastId,
      bodyClassName: bodyClass,
      transition: Slide,
      delay: delay,
    })
  }

  /** Shows a toast message styled as a "danger" or an "error" message. */
  static danger(
    content: ToastContent,
    toastId?: string,
    autoClose?: false | number,
    bodyClass = 'break-words'
  ): ReactText {
    return toast.error(content, {
      toastId,
      transition: Slide,
      bodyClassName: bodyClass,
      autoClose: autoClose,
    })
  }

  /** Shows a toast message styled as an "info" message. */
  static info(
    content: ToastContent,
    toastId?: string,
    autoClose?: false | number,
    delay?: number,
    bodyClass = 'break-words'
  ): ReactText {
    return toast.info(content, {
      toastId,
      transition: Slide,
      autoClose: autoClose,
      bodyClassName: bodyClass,
      delay: delay,
    })
  }
}
