import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import useNavData from '../../../hooks/useNavData'

type Props = {
  noRedirectOnLogo?: boolean
}

const StoreFooter: React.FC<Props> = ({ noRedirectOnLogo = false }) => {
  const { t } = useTranslation('footer')
  const { websiteLogo, hasAbout, hasPrivacyPolicy, hasTermsOfService, hasFaqs } = useNavData()

  return (
    <div className="p-4 lg:p-10 clear-both bg-theme-nav shadow-inner drop-shadow-bb">
      <div className="max-w-7xl m-auto">
        <ul className="md:float-left leading-loose text-center md:text-left">
          {hasAbout && (
            <li>
              <Link href="/about">
                <a className="text-bg-6 hover:text-bg-9 transition-colors">
                  {t('resources.footerAbout')}
                </a>
              </Link>
            </li>
          )}
          {hasPrivacyPolicy && (
            <li>
              <Link href="/privacy-policy">
                <a className="text-bg-6 hover:text-bg-9 transition-colors">
                  {t('resources.footerPrivacy')}
                </a>
              </Link>
            </li>
          )}
          {hasTermsOfService && (
            <li>
              <Link href="/terms-of-service">
                <a className="text-bg-6 hover:text-bg-9 transition-colors">
                  {t('resources.footerTerms')}
                </a>
              </Link>
            </li>
          )}
          {hasFaqs && (
            <li>
              <Link href="/frequently-asked-questions">
                <a className="text-bg-6 hover:text-bg-9 transition-colors">
                  {t('resources.footerFrequentlyAskedQuestions')}
                </a>
              </Link>
            </li>
          )}
        </ul>
        <div className="md:float-right mt-8 md:mt-0">
          {websiteLogo && (
            <>
              {noRedirectOnLogo ? (
                <Image
                  src={websiteLogo}
                  unoptimized
                  alt="Logo"
                  layout="raw"
                  height={50}
                  width={200}
                  className="mx-auto h-16 w-auto"
                />
              ) : (
                <Link href="/">
                  <a className={classNames('z-50', 'leading-0', 'shrink-0')}>
                    <Image
                      src={websiteLogo}
                      unoptimized
                      alt="Logo"
                      layout="raw"
                      height={50}
                      width={200}
                      className="mx-auto h-16 w-auto"
                    />
                  </a>
                </Link>
              )}
            </>
          )}
        </div>

        <div className="clear-both" />
      </div>
    </div>
  )
}

export default StoreFooter
