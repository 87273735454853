const DarkLangIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.35544 0.707104C7.97021 0.711876 6.61155 1.09159 5.42078 1.80674C4.23002 2.52189 3.25066 3.54635 2.58458 4.77356C1.91849 6.00077 1.59002 7.38589 1.63333 8.78484C1.67663 10.1838 2.09013 11.5455 2.83082 12.7282L1.62842 16.3729L5.24196 15.158C6.25912 15.8087 7.41229 16.211 8.61007 16.3332C9.80786 16.4554 11.0174 16.2941 12.1427 15.8619C13.2681 15.4298 14.2784 14.7389 15.0935 13.8437C15.9087 12.9486 16.5063 11.874 16.8391 10.705C17.1718 9.536 17.2306 8.30476 17.0106 7.10884C16.7907 5.91293 16.2981 4.7852 15.5719 3.81503C14.8457 2.84486 13.9059 2.05891 12.8268 1.51946C11.7478 0.980004 10.5593 0.701872 9.35544 0.707104V0.707104Z"
      stroke="#A5A5A5"
      strokeMiterlimit="10"
    />
    <path
      d="M9.38092 16.3728C11.3801 16.3728 13.0007 12.8659 13.0007 8.53994C13.0007 4.21394 11.3801 0.707031 9.38092 0.707031C7.38175 0.707031 5.76111 4.21394 5.76111 8.53994C5.76111 12.8659 7.38175 16.3728 9.38092 16.3728Z"
      stroke="#A5A5A5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.88171 4.26953C4.29295 5.26703 6.65979 5.93203 9.3557 5.93203C12.0516 5.93203 14.4754 5.2926 15.8613 4.26953"
      stroke="#A5A5A5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.77344 12.8955C4.14038 11.8532 6.57051 11.1562 9.38034 11.1562C12.1902 11.1562 14.5001 11.7957 15.886 12.8187"
      stroke="#A5A5A5"
      strokeMiterlimit="10"
    />
  </svg>
)
export default DarkLangIcon
