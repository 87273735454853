import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useUser from '../../../hooks/useUser'
import { getTokenCookie } from '../../../utils/cookie'
import Button from '../../Atoms/NewButton'
import UserMenu from '../UserMenu'

type Props = {
  loginClick?: () => void
}

const PageNavbar: React.FC<Props> = ({ loginClick }) => {
  const { t } = useTranslation(['navbar', 'common'])
  const [showUserMenu, setShowUserMenu] = useState(false)
  const token = useRef(getTokenCookie())
  const router = useRouter()
  const me = useUser()

  const handleEsc = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && showUserMenu) {
        setShowUserMenu(false)
      }
    },
    [showUserMenu]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  useEffect(() => {
    if (!token) setShowUserMenu(false)
  }, [token, me])

  const handleFocus = useCallback(() => {
    const currentToken = getTokenCookie()
    if ((me && !currentToken) || (!me && currentToken)) {
      router.reload()
    }
  }, [me, router])

  useEffect(() => {
    window.addEventListener('focus', handleFocus)
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [handleFocus])

  return (
    <div className="flex flex-row z-40 relative gap-4 items-center">
      {!token || !me ? (
        <Button onClick={loginClick} variant="primary">
          {t('navbar.button.login')}
        </Button>
      ) : (
        <UserMenu showUserMenu={showUserMenu} setShowUserMenu={setShowUserMenu} />
      )}
    </div>
  )
}

export default PageNavbar
