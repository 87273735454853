import classNames from 'classnames'
import React from 'react'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  errorBorder?: boolean
  notFull?: boolean
  noHover?: boolean
}

const Input = React.forwardRef<HTMLInputElement, Props>(function Input(props, ref) {
  const { className, errorBorder, noHover, notFull, ...rest } = props

  return (
    <input
      ref={ref}
      className={classNames(
        'border',
        { 'border-red-1': errorBorder, 'border-bg-4': !errorBorder },
        !noHover && 'focus:border-theme-primary',
        !noHover && 'focus:ring-theme-primary',
        !noHover && 'focus:ring-1',
        'transition-all',
        'bg-theme-background',
        'rounded-md',
        'block',
        'px-2',
        'py-1',
        'h-9',
        'text-xs',
        notFull ? '' : 'w-full',
        className
      )}
      {...rest}
    />
  )
})

export default Input
