import appConfig from '@betablocks/shared/lib/config/appConfig'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useChainIds from '../../../hooks/useChainIds'
import Tooltip from '../../Atoms/Tooltip'

const BannerIfTestnet: React.FC = () => {
  const chainIds = useChainIds()
  const { t } = useTranslation()

  if (chainIds.includes(1) || chainIds.includes(137) || appConfig.environment === 'Prod') {
    return null
  }

  return (
    <div
      className={classNames('fixed', 'border-t-2', 'border-t-theme-primary', 'w-full', 'z-[1000]')}
    >
      <div
        className={classNames(
          'bg-theme-primary',
          'mx-auto',
          'px-4',
          'rounded-b',
          'shadow',
          'select-none',
          'text-xs',
          'w-min',
          'whitespace-nowrap'
        )}
      >
        <Tooltip
          id="chainIDWarning"
          hoverText={t('chainIDWarning', { ns: 'common' })}
          textStyle="!text-bg-0 !font-normal"
          breakAll
          content="Test Networks"
        />
      </div>
    </div>
  )
}

export default BannerIfTestnet
