import React from 'react'

const ExportIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = ({
  className,
  width = 18,
  height = 18,
  fill = '#4E9BFB',
  ...rest
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.5325 5.78267L8.25 4.05767V11.2502C8.25 11.4491 8.32902 11.6398 8.46967 11.7805C8.61032 11.9211 8.80109 12.0002 9 12.0002C9.19891 12.0002 9.38968 11.9211 9.53033 11.7805C9.67098 11.6398 9.75 11.4491 9.75 11.2502V4.05767L11.4675 5.78267C11.5372 5.85296 11.6202 5.90876 11.7116 5.94683C11.803 5.98491 11.901 6.00451 12 6.00451C12.099 6.00451 12.197 5.98491 12.2884 5.94683C12.3798 5.90876 12.4628 5.85296 12.5325 5.78267C12.6028 5.71294 12.6586 5.62999 12.6967 5.5386C12.7347 5.4472 12.7543 5.34918 12.7543 5.25017C12.7543 5.15116 12.7347 5.05313 12.6967 4.96173C12.6586 4.87034 12.6028 4.78739 12.5325 4.71767L9.5325 1.71767C9.46117 1.64939 9.37706 1.59586 9.285 1.56017C9.1024 1.48515 8.8976 1.48515 8.715 1.56017C8.62294 1.59586 8.53883 1.64939 8.4675 1.71767L5.4675 4.71767C5.39757 4.7876 5.3421 4.87061 5.30426 4.96198C5.26641 5.05335 5.24693 5.15127 5.24693 5.25017C5.24693 5.34906 5.26641 5.44699 5.30426 5.53835C5.3421 5.62972 5.39757 5.71274 5.4675 5.78267C5.53743 5.8526 5.62045 5.90807 5.71181 5.94591C5.80318 5.98376 5.90111 6.00323 6 6.00323C6.09889 6.00323 6.19682 5.98376 6.28819 5.94591C6.37955 5.90807 6.46257 5.8526 6.5325 5.78267ZM15.75 10.5002C15.5511 10.5002 15.3603 10.5792 15.2197 10.7198C15.079 10.8605 15 11.0513 15 11.2502V14.2502C15 14.4491 14.921 14.6398 14.7803 14.7805C14.6397 14.9211 14.4489 15.0002 14.25 15.0002H3.75C3.55109 15.0002 3.36032 14.9211 3.21967 14.7805C3.07902 14.6398 3 14.4491 3 14.2502V11.2502C3 11.0513 2.92098 10.8605 2.78033 10.7198C2.63968 10.5792 2.44891 10.5002 2.25 10.5002C2.05109 10.5002 1.86032 10.5792 1.71967 10.7198C1.57902 10.8605 1.5 11.0513 1.5 11.2502V14.2502C1.5 14.8469 1.73705 15.4192 2.15901 15.8412C2.58097 16.2631 3.15326 16.5002 3.75 16.5002H14.25C14.8467 16.5002 15.419 16.2631 15.841 15.8412C16.2629 15.4192 16.5 14.8469 16.5 14.2502V11.2502C16.5 11.0513 16.421 10.8605 16.2803 10.7198C16.1397 10.5792 15.9489 10.5002 15.75 10.5002Z"
        fill={fill}
      />
    </svg>
  )
}
export default ExportIcon
