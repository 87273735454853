import classNames from 'classnames'
import dynamic from 'next/dynamic'
import React, { useId } from 'react'
import TooltipIcon from '../../../assets/images/tooltip/TooltipIcon'

const ReactTooltip = dynamic(() => import('./shim'), {
  ssr: false,
})

type Props = {
  id?: string
  content?: React.ReactNode
  hoverText: string
  icon?: React.ReactNode | false
  textStyle?: string
  className?: string
  breakAll?: boolean
  width?: string
}

const Tooltip: React.FC<Props> = ({
  id,
  content,
  hoverText,
  icon,
  textStyle,
  className,
  breakAll,
}) => {
  const _id = useId()
  const tooltipId = id || _id

  return (
    <div className={classNames('flex flex-row cursor-pointer')}>
      <div
        data-tip
        data-for={tooltipId}
        className={classNames('whitespace-nowrap', 'overflow-hidden', 'text-ellipsis', className)}
      >
        <div
          className={classNames(
            'relative',
            'items-center',
            'flex',
            'flex-row',
            'group',
            'gap-1',
            'font-bold',
            'capitalize',
            'text-xs',
            textStyle
          )}
        >
          {content}
          {icon === false ? null : icon ?? <TooltipIcon />}
        </div>
      </div>

      <ReactTooltip
        effect="solid"
        id={tooltipId}
        backgroundColor="var(--color-primary)"
        arrowColor="var(--color-primary)"
        delayShow={150}
        delayUpdate={150}
      >
        <p
          className={classNames(
            'max-w-430px',
            'font-normal',
            'text-center',
            'normal-case',
            'whitespace-normal',
            breakAll ? 'break-all' : 'break-normal'
          )}
        >
          {hoverText}
        </p>
      </ReactTooltip>
    </div>
  )
}

export default Tooltip
