import React from 'react'

const PreviewIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = ({
  className,
  width = 20,
  height = 20,
  ...rest
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.3327 5.33464H3.21935L6.94602 1.60797L5.99935 0.667969L0.666016 6.0013L5.99935 11.3346L6.93935 10.3946L3.21935 6.66797H11.3327V5.33464Z"
      fill="currentColor"
    />
  </svg>
)

export default PreviewIcon
