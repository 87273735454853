import React from 'react'

const TooltipIcon: React.FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333984 7.00016C0.333984 3.32016 3.32065 0.333496 7.00065 0.333496C10.6807 0.333496 13.6673 3.32016 13.6673 7.00016C13.6673 10.6802 10.6807 13.6668 7.00065 13.6668C3.32065 13.6668 0.333984 10.6802 0.333984 7.00016ZM7.66732 9.66683V11.0002H6.33398V9.66683H7.66732ZM7.00065 12.3335C4.06065 12.3335 1.66732 9.94016 1.66732 7.00016C1.66732 4.06016 4.06065 1.66683 7.00065 1.66683C9.94065 1.66683 12.334 4.06016 12.334 7.00016C12.334 9.94016 9.94065 12.3335 7.00065 12.3335ZM4.33398 5.66683C4.33398 4.1935 5.52732 3.00016 7.00065 3.00016C8.47398 3.00016 9.66732 4.1935 9.66732 5.66683C9.66732 6.52211 9.14064 6.98238 8.62783 7.43052C8.14133 7.85567 7.66732 8.26991 7.66732 9.00016H6.33398C6.33398 7.78598 6.96206 7.30455 7.51428 6.88128C7.94747 6.54923 8.33398 6.25297 8.33398 5.66683C8.33398 4.9335 7.73398 4.3335 7.00065 4.3335C6.26732 4.3335 5.66732 4.9335 5.66732 5.66683H4.33398Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default TooltipIcon
