import classNames from 'classnames'
import Image from 'next/image'
import React, { useRef, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import BlankUser from '../../../assets/images/blank-user.svg'
const Avatar: React.FC<{
  alt?: string
  src?: string
  width: number
  height: number
  onChange?: (dataUri: string) => Promise<void>
}> = ({ alt, src, width, height, onChange }) => {
  const ref = useRef<HTMLInputElement>(null)
  const [image, setImage] = useState<Blob | string>(src)

  const handleClick = (): void => {
    ref.current?.click()
  }

  const handleChange = async (): Promise<void> => {
    const file = ref.current?.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (): void => {
        setImage(reader.result as string)
        try {
          onChange?.(reader.result as string)
        } catch (e) {
          setImage(src)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      {onChange ? (
        <input type="file" ref={ref} onChange={handleChange} className="hidden" accept="image/*" />
      ) : null}

      <div
        style={{ width, height }}
        className={classNames('inline-block', 'cursor-pointer', 'relative', 'group')}
        onClick={onChange ? handleClick : undefined}
        onKeyPress={onChange ? handleClick : undefined}
        role={onChange ? 'button' : undefined}
        tabIndex={onChange ? 0 : undefined}
      >
        {onChange ? (
          <div
            className={classNames([
              'absolute',
              'bg-bg-6',
              'bottom-0',
              'flex',
              'group-hover:opacity-50',
              'items-center',
              'justify-center',
              'left-0',
              'opacity-0',
              'right-0',
              'rounded-full',
              'text-2xl',
              'text-bg-0',
              'top-0',
              'transition-all',
              'z-50',
            ])}
          >
            <FaPencilAlt />
          </div>
        ) : null}
        <Image
          src={image || BlankUser}
          unoptimized
          alt={alt}
          height={width}
          width={height}
          objectFit="contain"
          className="rounded-full"
        />
      </div>
    </>
  )
}

export default Avatar
