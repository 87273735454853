import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useState } from 'react'
import useNavData from '../../../hooks/useNavData'
import useUser from '../../../hooks/useUser'
import SettingsSidebarItem from '../../Atoms/SidebarItem'
import LanguagePicker from '../LanguagePicker'
import PageNavbar from '../PageNavbar'
import WalletPicker from '../WalletPicker'

type Props = {
  loginClick?: () => void
}

const StorefrontSidebar: React.FC<Props> = ({ loginClick }) => {
  const { t } = useTranslation('navbar')
  const navData = useNavData()
  const user = useUser()
  const homePage = navData?.pages.find((page) => page.isDefault) || { slug: '', title: 'Home' }
  const otherPages = navData?.pages.filter((page) => !page.isDefault) || []

  const [showStaticPages, setShowStaticPages] = useState<boolean>(false)
  const hasStaticPages = navData.hasStaticPages

  const handleMoreClick = (): void => {
    setShowStaticPages((prev) => !prev)
  }
  return (
    <nav>
      <ul className="pt-8">
        <Link href={`/${homePage.slug}`} passHref>
          <SettingsSidebarItem>{homePage.title}</SettingsSidebarItem>
        </Link>
        <Link href="/browse" passHref>
          <SettingsSidebarItem>{t('navbar.navbarBrowse')}</SettingsSidebarItem>
        </Link>
        {otherPages.map((page) => (
          <Link href={`/${page.slug}`} passHref key={page.slug}>
            <SettingsSidebarItem>{page.title}</SettingsSidebarItem>
          </Link>
        ))}

        {hasStaticPages && (
          <SettingsSidebarItem onClick={handleMoreClick}>
            {t('navbar.navbarMore')}
          </SettingsSidebarItem>
        )}

        {showStaticPages &&
          navData.staticPages.map((page) => (
            <SettingsSidebarItem key={page.slug} href={`/more/${page.slug}/`} indent>
              {page.title}
            </SettingsSidebarItem>
          ))}
      </ul>
      <div className="pt-4 mt-4 mx-8 border-t">
        <div className="-mx-4">
          <LanguagePicker />
        </div>
        {user && (
          <div className="-mx-4">
            <WalletPicker />
          </div>
        )}
        <div className="pt-4">
          <PageNavbar loginClick={loginClick} />
        </div>
      </div>
    </nav>
  )
}

export default StorefrontSidebar
