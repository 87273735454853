import React from 'react'

export type LoginPaneContextProps = {
  toggleLoginPane: () => void
}

const LoginPaneContext = React.createContext<LoginPaneContextProps>({
  toggleLoginPane: () => {
    // void
  },
})

export default LoginPaneContext
