import classNames from 'classnames'
import Image from 'next/image'
import XIcon from '../../../assets/images/x-icon.svg'
import { useTranslation } from 'next-i18next'
import useApi from '../../../hooks/useApi'
import { Toast } from '../../../utils/toast'
import Button from '../../Atoms/NewButton'
import useUser from '../../../hooks/useUser'

type Props = {
  onClose: () => void
}

const VerifyEmailPopup: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common')

  const api = useApi()
  const { email } = useUser()

  const handleVerification = async (): Promise<void> => {
    await api.auth.resendVerificationEmail({ email })
    Toast.success(t('verifyEmail.resendVerificationSuccess'))
  }

  return (
    <div
      className={classNames(
        'justify-center items-center flex fixed inset-0 z-[1000] outline-none focus:outline-none transition-all bg-black bg-opacity-70'
      )}
    >
      <div
        className={classNames(
          'sm:max-w-[500px]',
          'max-w-[90%]',
          'w-full',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'rounded-lg',
          'shadow-lg',
          'bg-white',
          'transition-all',
          'sm:p-10',
          'p-5',
          'sm:m-20',
          'm-3'
        )}
      >
        <button
          onClick={onClose}
          className="self-end transform hover:scale-110 active:scale-90 cursor-pointer"
        >
          <Image src={XIcon} alt="close" />
        </button>
        <div className="flex flex-col items-center w-full gap-5">
          <div className="flex flex-col items-center text-center gap-2">
            <h3 className="font-bold text-lg mt-[-30px]">{t('verifyEmail.title')}</h3>
            <p className="text-center max-w-[400px]">{t('verifyEmail.description')}</p>
          </div>
          <Button variant="primary" onClick={handleVerification} className="w-full">
            {t('verifyEmail.resendVerification')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmailPopup
