import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import MenuCloseIcon from '../../../assets/icons/MenuToggleCloseIcon'
import MenuOpenIcon from '../../../assets/icons/MenuToggleOpenIcon'
import useNavData from '../../../hooks/useNavData'
import useUser from '../../../hooks/useUser'
import NavbarItem from '../../Atoms/NavbarItem'
import Button from '../../Atoms/NewButton'
import MoreNavBar from '../../Widgets/MoreNavBar'
import BannerIfTestnet from '../BannerIfTestnet'
import LanguagePicker from '../LanguagePicker'
import PageNavbar from '../PageNavbar'
import WalletPicker from '../WalletPicker'

const Navbar: React.FC<
  React.PropsWithChildren<{
    /** Whether or not to show the hamburger menu on mobile. */
    mobileHamburgerShow?: boolean | null

    /** Toggle the hamburger menu on mobile. */
    onMobileHamburgerToggle?: () => void

    subheader?: string
    storefront?: number
    hideNav?: boolean
    noRedirectOnLogo?: boolean

    loginClick?: () => void
  }>
> = ({
  children,
  mobileHamburgerShow = null,
  hideNav,
  noRedirectOnLogo = false,
  onMobileHamburgerToggle,
  subheader,
  loginClick,
}) => {
  const { t } = useTranslation('navbar')
  const navData = useNavData()
  const user = useUser()
  const homePage = navData?.pages.find((page) => page.isDefault) || { slug: '', title: 'Home' }
  const storefrontLogo = navData?.websiteLogo || '/images/LogoBlack.png'

  return (
    <nav
      className={classNames(
        'relative',
        'grid',
        'grid-cols-2',
        { 'lg:grid-cols-3': !hideNav },
        'gap-10',
        'bg-transparent',
        'h-16',
        'lg:pl-6',
        'pr-6',
        'z-50',
        'before:absolute',
        'before:top-0',
        'before:right-0',
        'before:bottom-0',
        'before:left-0',
        'before:bg-theme-nav',
        'before:drop-shadow-bb'
      )}
    >
      <div className="place-self-start self-center h-full flex items-center lg:gap-1.5 w-full z-50">
        {hideNav ? null : mobileHamburgerShow !== null ? (
          <Button
            variant="textBlack"
            className="block lg:hidden shrink-0"
            onClick={onMobileHamburgerToggle}
          >
            {mobileHamburgerShow ? (
              <MenuCloseIcon width={20} height={20} className="fill-current" />
            ) : (
              <MenuOpenIcon width={20} height={20} className="fill-current" />
            )}
          </Button>
        ) : null}

        {storefrontLogo && noRedirectOnLogo && (
          <Image
            src={storefrontLogo}
            unoptimized
            alt="Logo"
            layout="raw"
            height={48}
            width={200}
            className="h-12 w-auto"
          />
        )}

        {!noRedirectOnLogo && (
          <Link href="/">
            <a
              className={classNames('z-50', 'leading-0', 'shrink-0', {
                'hidden lg:block': !!subheader,
              })}
            >
              {storefrontLogo && (
                <Image
                  src={storefrontLogo}
                  unoptimized
                  alt="Logo"
                  layout="raw"
                  height={48}
                  width={200}
                  className="h-12 object-contain max-w-[200px]"
                />
              )}
            </a>
          </Link>
        )}

        {subheader ? (
          <div className="lg:pl-2 lg:ml-1 text-md lg:text-sm font-navbarSubheading select-none whitespace-nowrap z-50">
            {subheader}
          </div>
        ) : null}
      </div>

      {!hideNav && (
        <div className="hidden place-self-center self-center lg:flex gap-9 z-50">
          <NavbarItem href={`/${homePage.slug}`}>{homePage.title}</NavbarItem>
          {navData?.pages.map(
            (page, idx) =>
              page.title != homePage.title && (
                <NavbarItem key={idx} href={`/${page.slug}`}>
                  {page.title}
                </NavbarItem>
              )
          )}
          <NavbarItem href={`/browse`}>{t('navbar.navbarBrowse')}</NavbarItem>
          {navData?.hasStaticPages && <MoreNavBar />}
        </div>
      )}

      <div
        className={classNames('place-self-end self-center content-end flex', {
          'w-full flex-row-reverse': hideNav,
          'flex-row': !hideNav,
        })}
      >
        <div className="place-self-end self-center hidden lg:block mr-4">
          <LanguagePicker />
        </div>
        {user && (
          <div className="place-self-end self-center hidden lg:block mr-4">
            <WalletPicker />
          </div>
        )}
        {children}
        {!hideNav && (
          <div className="place-self-end self-center hidden lg:block">
            <PageNavbar loginClick={loginClick} />
          </div>
        )}
      </div>

      <div className="absolute -bottom-0 z-10">
        <BannerIfTestnet />
      </div>
    </nav>
  )
}

export default Navbar
