import { darken, lighten } from 'polished'
import React from 'react'
import useNavData from '../../../hooks/useNavData'

const Theme: React.FC<React.PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const {
    theme: { themeData },
  } = useNavData()

  const { dark } = themeData

  const fade = dark ? darken : lighten

  return (
    <div>
      <style jsx>{`
        * {
          --color-primary: ${themeData.colors.primary};
          --color-primary-light: ${lighten(0.25, themeData.colors.primary)};
          --color-primary-lighter: ${lighten(0.35, themeData.colors.primary)};
          --color-primary-dark: ${darken(0.25, themeData.colors.primary)};
          --color-primary-darker: ${darken(0.35, themeData.colors.primary)};

          --color-secondary: ${themeData.colors.secondary};
          --color-secondary-light: ${lighten(0.25, themeData.colors.secondary)};
          --color-secondary-lighter: ${lighten(0.35, themeData.colors.secondary)};
          --color-secondary-dark: ${darken(0.25, themeData.colors.secondary)};
          --color-secondary-darker: ${darken(0.35, themeData.colors.secondary)};

          --color-background: ${themeData.colors.background};
          --color-background-light: ${lighten(0.25, themeData.colors.background)};
          --color-background-dark: ${darken(0.25, themeData.colors.background)};
          --color-background-signup: ${lighten(0.25, themeData.colors.primary)};

          --color-default: ${themeData.colors.default};
          --color-default-light: ${lighten(0.25, themeData.colors.default)};
          --color-default-dark: ${darken(0.25, themeData.colors.default)};

          --color-default-0: var(--color-background);
          --color-default-1: ${fade(0.9, themeData.colors.default)};
          --color-default-2: ${fade(0.8, themeData.colors.default)};
          --color-default-3: ${fade(0.70625, themeData.colors.default)};
          --color-default-4: ${fade(0.6125, themeData.colors.default)};
          --color-default-5: ${fade(0.51875, themeData.colors.default)};
          --color-default-6: ${fade(0.425, themeData.colors.default)};
          --color-default-7: ${fade(0.33125, themeData.colors.default)};
          --color-default-8: ${fade(0.2375, themeData.colors.default)};
          --color-default-9: ${fade(0.14375, themeData.colors.default)};
          --color-default-10: ${fade(0.05, themeData.colors.default)};
          --color-default-11: var(--color-default);

          --color-nav: ${themeData.colors.nav};
          --color-shadow: ${themeData.colors.shadow ?? 'var(--color-primary-light)'};

          --fonts-primary: ${themeData.fonts.primary}, sans-serif;
          --fonts-secondary: ${themeData.fonts.secondary}, sans-serif;
          --fonts-base-size: ${themeData.fonts.base}px;
        }

        a,
        ul,
        ol,
        li,
        dd,
        span,
        div,
        main,
        section,
        button,
        input,
        form,
        footer,
        header,
        label,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: var(--color-default);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: var(--fonts-secondary);
        }

        td,
        tr,
        th {
          border-color: var(--color-secondary);
          color: var(--color-default);
        }

        input {
          color: var(--color-default);
          font-family: var(--fonts-primary);
        }

        nav {
          color: var(--color-nav);
        }

        * input[type='checkbox'] {
          -moz-appearance: none !important;
          -webkit-appearance: none !important;
          appearance: none !important;
          background: var(--color-primary) !important;
        }
      `}</style>
      {children}
    </div>
  )
}

export default Theme
