import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../Input'
import Text from '../Text'
import Tooltip from '../Tooltip'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title'> & {
  title?: string
  noMargin?: boolean
  errorText?: string
  optional?: boolean
  hoverText?: string
  onClick?: () => void
  inputLen?: number
  lengthLimit?: number
  showInputLen?: boolean
  inputClassName?: string
  prefix?: string
  titleAccessory?: ReactNode
}

const LabelledInput = React.forwardRef<HTMLInputElement, Props>(function LabelledInput(
  {
    children,
    title,
    optional,
    prefix,
    errorText,
    className,
    inputClassName,
    id,
    noMargin,
    hoverText,
    onClick,
    inputLen,
    lengthLimit,
    showInputLen = false,
    titleAccessory,
    ...rest
  },
  ref
) {
  const { t } = useTranslation(['common'])

  return (
    <div className={classNames(!noMargin && 'mb-6', className)}>
      <div className="flex flex-row justify-between">
        {title || optional || hoverText ? (
          <div className="flex flex-row gap-1 pb-2">
            {title && (
              <label
                htmlFor={id}
                className="capitalize block text-bg-10 font-bold font-heading text-xs"
              >
                {title}
              </label>
            )}
            {optional && (
              <label htmlFor={id} className="capitalize block text-bg-6 font-light text-xs">
                {t('optional')}
              </label>
            )}
            {hoverText && <Tooltip id={id} hoverText={hoverText} />}
          </div>
        ) : null}
        {!errorText && titleAccessory && <span className="self-end pb-2"> {titleAccessory} </span>}
        {errorText ? <div className="text-xs text-red-1 pb-2">{errorText}</div> : false}
      </div>

      <div className="flex flex-row gap-1">
        {prefix && (
          <p className="text-bg-10 font-bold text-xs pr-1.5 flex flex-wrap content-center">
            {prefix}
          </p>
        )}
        <div className="flex flex-1 flex-col">
          <Input
            ref={ref}
            id={id}
            errorBorder={errorText ? true : false}
            className={inputClassName}
            {...rest}
          />
          {hoverText && (
            <div
              onClick={onClick}
              onKeyPress={onClick}
              tabIndex={0}
              role="button"
              className="flex flex-row-reverse text-bg-10 capitalize text-xs"
            >
              {hoverText}
            </div>
          )}
        </div>
      </div>

      {showInputLen && (
        <Text className="flex flex-row-reverse text-xs" as="p" color="text-bg-7">
          {inputLen ? inputLen : 0}/{lengthLimit}
        </Text>
      )}

      {children}
    </div>
  )
})

export default LabelledInput
