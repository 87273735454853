import classNames from 'classnames'
import Link, { LinkProps } from 'next/link'
import React, { ReactNode } from 'react'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  outline?: boolean
  variant?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'textBlue'
    | 'primaryGreen'
    | 'primaryGray'
    | 'blueGray8'
    | 'secondaryGray'
    | 'capsBlack'
    | 'default'
    | 'textBlack'
    | 'textBG8'
  onClick?: React.MouseEventHandler<HTMLButtonElement> | (() => void)
  box?: boolean
  href?: LinkProps['href']
  icon?: ReactNode
  iconAlign?: 'left' | 'right'
  type?: 'button' | 'submit' | 'reset'
  align?: 'left' | 'right' | 'both'
  caps?: boolean
  color?: string
  textColor?: string
  thin?: boolean
  locale?: 'en' | 'pt' | false
}

const Button: React.FC<React.PropsWithChildren<Props>> = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      className: extraClassName,
      outline,
      variant,
      onClick,
      box,
      href,
      icon,
      iconAlign = 'right',
      type = 'button',
      align,
      color,
      textColor,
      disabled,
      caps,
      locale,
      thin = false,
      ...rest
    },
    ref
  ) => {
    const size = box ? ['max-w-48'] : []

    const className = classNames(
      [
        extraClassName,
        'transition-all',
        'inline-block',
        'rounded',
        ...size,
        thin ? 'h-4' : 'h-9',
        'leading-0',
        'text-xs',
        'tracking-wider',
        'font-medium',
        'hover:ring-opacity-50',
        'active:transform',
        'active:scale-90',
        'text-center',
        'flex',
        'justify-center',
        'items-center',
        'whitespace-nowrap',
      ],

      { '!uppercase': caps },

      variant === 'primary' &&
        !outline &&
        `bg-theme-primary hover:ring hover:ring-theme-primaryLight text-bg-0 capitalize`,
      variant === 'primary' &&
        outline &&
        `bg-transparent hover:ring hover:ring-theme-primaryLight hover:border-theme-primary border border-bg-11 text-bg-11 capitalize`,

      variant === 'secondary' &&
        !outline &&
        `bg-theme-secondary hover:ring hover:ring-theme-secondaryLight`,
      variant === 'secondary' &&
        outline &&
        `bg-transparent hover:ring hover:ring-theme-secondaryLight hover:border-theme-secondary border border-bg-11 text-bg-11`,

      variant === 'danger' &&
        !outline &&
        `bg-red-600 hover:ring hover:ring-red-600 text-bg-0 capitalize`,
      variant === 'danger' &&
        outline &&
        `bg-theme-background hover:bg-red-600 hover:text-bg-0 border border-red-600 text-red-600 capitalize`,

      variant === 'textBlue' &&
        !outline &&
        `text-theme-primary hover:underline hover:text-theme-primaryDark capitalize`,
      variant === 'textBlue' &&
        outline &&
        `bg-theme-background border border-theme-primary text-theme-primary hover:text-theme-primaryDark hover:border-theme-primaryDark capitalize`,

      variant === 'textBlack' && `hover:underline capitalize`,
      variant === 'textBlack' && outline && `hover:underline capitalize border border-black`,

      variant === 'textBG8' && `text-bg-8 hover:underline capitalize`,
      variant === 'textBG8' && outline && `text-bg-8 hover:underline capitalize border border-bg-8`,

      variant === 'primaryGreen' &&
        !outline &&
        `bg-green-1 hover:ring hover:ring-green-1 text-bg-0 capitalize`,
      variant === 'primaryGreen' &&
        outline &&
        `hover:border-green-2 border border-green-1 text-bg-0 capitalize`,

      variant === 'primaryGray' &&
        !outline &&
        `bg-bg-7 hover:ring hover:ring-bg-7 text-bg-0 capitalize`,
      variant === 'primaryGray' &&
        outline &&
        `bg-bg-7 hover:bg-black hover:opacity-100 border border-opacity-50 hover:border-0 text-bg-0 capitalize`,

      variant === 'blueGray8' &&
        !outline &&
        `bg-bg-8 hover:ring hover:ring-bg-8 text-bg-0 capitalize`,
      variant === 'blueGray8' &&
        outline &&
        `bg-bg-8 hover:bg-black hover:opacity-100 border border-opacity-50 hover:border-0 text-bg-0 capitalize`,

      variant === 'secondaryGray' && !outline && `text-bg-7 capitalize`,
      variant === 'secondaryGray' &&
        outline &&
        `bg-black hover:bg-bg-7 border border-opacity-50 text-bg-0 capitalize`,

      variant === 'capsBlack' && !outline && ` text-bg-11 hover:underline uppercase`,

      variant === 'default' && !outline && `bg-bg-1 hover:ring hover:ring-bg-3 text-bg-11`,
      variant === 'default' &&
        outline &&
        `bg-theme-background hover:bg-bg-1 border border-bg-3 text-bg-11 hover:text-bg-11`,

      align === 'left' || align === 'both' ? 'pl-0' : 'pl-8',
      align === 'right' || align === 'both' ? 'pr-0' : 'pr-8',
      disabled ? 'opacity-25 pointer-events-none' : ''
    )

    if (href) {
      return (
        <Link href={disabled ? '#' : href} locale={locale}>
          <a
            aria-disabled={disabled}
            ref={ref}
            className={classNames(className)}
            style={{
              backgroundColor: color || '',
              color: textColor || '',
            }}
          >
            {icon && iconAlign === 'left' && (
              <span className={children ? 'pr-2' : undefined}>{icon}</span>
            )}
            {children}
            {icon && iconAlign === 'right' && (
              <span className={children ? 'pl-2' : undefined}>{icon}</span>
            )}
          </a>
        </Link>
      )
    }

    return (
      <button
        className={classNames(className)}
        onClick={onClick}
        type={type}
        disabled={disabled}
        style={{
          backgroundColor: color || '',
          color: textColor || '',
        }}
        {...rest}
      >
        {icon && iconAlign === 'left' && (
          <span className={children ? 'pr-2' : undefined}>{icon}</span>
        )}
        {children}
        {icon && iconAlign === 'right' && (
          <span className={children ? 'pl-2' : undefined}>{icon}</span>
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'
export default Button
