import classNames from 'classnames'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

const NavbarItem: React.FC<React.PropsWithChildren<LinkProps>> = (props) => {
  const router = useRouter()

  const { href } = props
  const isActive = router.asPath === href || router.asPath === `${href}/`

  return (
    <Link {...props}>
      <a
        className={classNames('hover:text-theme-primary', 'transition-all', 'text-xs', {
          'font-bold text-theme-primary ': isActive,
          'font-normal text-bg-11': !isActive,
        })}
      >
        {props.children}
      </a>
    </Link>
  )
}

export default NavbarItem
